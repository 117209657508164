import React from 'react'
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import HeaderTwo from '../common/header/HeaderTwo';
import FooterFour from '../common/footer/FooterFour';

import Slider from "react-slick";
import { BannerActivation } from "../utils/script";
import aboutlg from "../assets/images/aboutlg.webp";
import BlogClassicData from '../data/blog/BlogList.json';
import staff from "../assets/images/staff.jpg";
import LogisticsServices from "../assets/images/LogisticsServices.png";
import CustomerSupport from "../assets/images/CustomerSupport.png";
import SolutionOne from '../elements/SolutionOne';
import CounterOne from '../elements/CounterOne';
import FaqOne from '../elements/FaqOne';

var BlogListData = BlogClassicData.slice(0, 3);
const BannerData = [
    {
        image: "/images/bg/bnr.png",
        title: " Trust Your Logistics to Our Expertise!",
        description: "Revolutionize Your Logistics Business with Dmveer Tech Solutions Marketing's Cutting-Edge Services"
    },
  
]


const Myhome = () => {
    return (
        <>
            {/* <SEO title="Digital Agency" /> */}
            <main className="page-wrapper">
                
                <HeaderTwo btnStyle="btn-small" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <Slider className="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow" {...BannerActivation}>
                    {BannerData.map((data, index) => (
                        <div key={index} className="single-slide">
                            <div className="height-850 bg-overlay bg_image" style={{backgroundImage: `url(${process.env.PUBLIC_URL} ${data.image})`}}>
                                <div className="container">
                                    <div className="row row--30 align-items-center">
                                        <div className="order-2 order-lg-1 col-lg-7">
                                            <div className="inner text-left">
                                                <h1 className="title" dangerouslySetInnerHTML={{__html: data.title}}></h1>
                                                <p className="description" dangerouslySetInnerHTML={{__html: data.description}}></p>
                                                <div className="button-group mt--30">
                                                    <a className="btn-default" target="_blank" href="tel:+91 87290-56066">CONTACT NOW</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
                {/* End Slider Area  */}

                <section className="abhtbnr" id="aboutus">
  <div className="container">
    <div className="row">
    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
<img src={aboutlg} className='aboutlg' alt='' />
    </div>
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
        <h2>Streamlining your Supply Chain with Precision and Reliability</h2>
        <p>we understand the critical importance of a streamlined and efficient supply chain for businesses. That's why we are dedicated to providing you with logistics solutions that prioritize precision and reliability. Our team of experts works tirelessly to optimize every aspect of your supply chain, from transportation and warehousing to inventory management and distribution.
        </p>
        <p>We pride ourselves on our unwavering commitment to reliability. We understand that your success relies on timely and accurate deliveries, and we go above and beyond to meet and exceed your expectations. Our robust tracking and monitoring systems enable real-time visibility of your shipments, providing you with the confidence and peace of mind that your goods are in safe hands.</p>
        <div className="button-group mt--30">
        <a className="btn-default" target="_blank" href="tel:+91 87290-56066 ">CONTACT NOW</a>
          </div>
      </div>
    </div>
  </div>
</section>
<section className="benefit" >
  <div className="container">
    <div className="row">
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12" id="commodo">
        <div className="staffbenfits">
          <h3> 
          <span style={{ color: "#f02830" }}>01.</span>
          Expert Staff
          </h3>
          <hr />
          <img src={staff} className="staff" alt="staff" />
          <p>Dmveer Tech Solutions Marketing boasts a team of seasoned professionals with extensive experience in the logistics industry. Our expert staff is equipped with in-depth knowledge and a keen understanding of marketing dynamics, ensuring tailored strategies that deliver exceptional results.</p>
        </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12" id="commodo">
        <div className="staffbenfits">
          <h3> 
          <span style={{ color: "#f02830" }}>01.</span>
        Logistics Services
          </h3>
          <hr />
          <img src={LogisticsServices} className="staff" alt="staff" />
          <p>Dmveer Tech Solutions Marketing provides comprehensive logistics services that streamline operations, optimize supply chain management, and enhance overall efficiency. From transportation and warehousing solutions to inventory management and order fulfilment, we've got you covered.</p>
        </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12" id="commodo">
        <div className="staffbenfits">
          <h3> 
          <span style={{ color: "#f02830" }}>03.</span>
       Customer Support
          </h3>
          <hr />
          <img src={CustomerSupport} className="staff" alt="staff" />
          <p>At Dmveer Tech Solutions Marketing, we prioritize customer satisfaction by offering exceptional support services. Our dedicated customer support team is available round-the-clock to address your queries, provide guidance, and ensure a seamless experience throughout your partnership with us.</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="ser" id="Services">
  <div className="container">
  <div className="row">
    <div className="col-xx-12">
      <h2>What we can do for you</h2>
      <p className="Praesent">we are committed to providing comprehensive logistics solutions that cater to your specific needs <br />and drive your business forward. Here's what we can do for you:</p>
    </div>
  </div>
    <div className="row" id="serrow">
    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
        <div className="servicebox">
        {/* <img src={warehouse} className="warehouse" alt="warehouse" /> */}
          <h4>Warehousing Service</h4>
          <p>Dmveer Tech Solutions Service provides secure and efficient warehousing solutions, optimizing storage, inventory management, and order fulfillment for your business needs.</p>
        </div>
      </div>
      <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
        <div className="servicebox">
                 <h4>Full Truckload Shipping</h4>
          <p>Trust Dmveer Tech Solutions Service for reliable and cost-effective full truckload shipping, ensuring timely and seamless transportation of your goods across any distance.</p>
        </div>
      </div>
      <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
        <div className="servicebox">
          <h4>Less than container load</h4>
          <p>With Dmveer Tech Solutions Service's expertise in less than container load (LCL) shipping, you can efficiently transport smaller shipments while enjoying the benefits of cost savings and flexibility.</p>
        </div>
      </div>
 <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
        <div className="servicebox">
          <h4>Dispatch Service</h4>
          <p>Dmveer Tech Solutions Service offers efficient dispatch services, ensuring prompt and reliable coordination of shipments, minimizing delays, and maximizing operational efficiency.</p>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
        <div className="servicebox">
     
          <h4>Transload Services</h4>
          <p>Benefit from Dmveer Tech Solutions Service's transload services, seamlessly transferring goods between different modes of transportation, optimizing logistics operations and reducing costs.</p>
        </div>
      </div>
      <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
        <div className="servicebox">
 
          <h4>Brokerage</h4>
          <p>Dmveer Tech Solutions Service's expert brokerage services provide a seamless experience for your freight needs, handling the complexities
of paperwork, regulations, and negotiations to ensure smooth transportation.</p>
        </div>
      </div>
      <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
        <div className="servicebox">
     
          <h4>Flatbed Freight</h4>
          <p>Trust Dmveer Tech Solutions Service's specialized flatbed freight solutions for safe and efficient transportation of oversized, heavy, or unconventional cargo, tailored to meet your specific requirements.</p>
        </div>
      </div>
      <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
        <div className="servicebox">
    
          <h4>Intermodal Shipping</h4>
          <p>Dmveer Tech Solutions Service offers efficient intermodal shipping solutions, seamlessly combining multiple modes of transportation to optimize cost, time, and environmental impact for your shipments.</p>
        </div>
      </div>
    </div>
  </div>
</section>
<SolutionOne />
<CounterOne />
<FaqOne />
             
                <FooterFour />
            </main>
        </>
    )
}
export default Myhome;